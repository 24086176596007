.project-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--Grays-G10, rgba(21, 24, 31, 0.501));
}

.project-message .message{
    display: flex;
    width: 375px;
    height: auto;
    padding: 32px 40px 32px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-radius: 16px;
    background: white;
}

.project-message .message .message-text {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.project-message .message .message-text h1{
    display: -webkit-box;
    width: 311px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--Grays-Black, #000);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;

    /* Heading/H5 - 24 */
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
}

.project-message .message .message-text p{
    color: var(--Grays-G7, var(--Grays-G7, #767C8C));
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    
    /* Body/Normal/Medium - 16 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

@media screen and (max-width: 425px) {
    .project-message .message{
        width: 275px;
        padding: 32px 10px;
    }

    .project-message .message .message-text h1{
        width: 260px;
    }
}