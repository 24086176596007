.contact-hero-section {
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
    padding: 60px 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0%;
}

.contact-hero-section .contact-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

.form-control:focus, .form-select:focus{
    outline: none !important;
    /* border: none !important; */
    box-shadow: none !important;
    border-color: #000;
    background-color: transparent !important;
}

.contact-hero-section .contact-title > h1 {
    color: var(--Text-T---Black, #000);
    text-align: center;
    leading-trim: both;
    text-edge: cap;

    /* Heading/H3 - 40 */
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
}

.contact-hero-section .contact-title > p {
    color: var(--Text-T---Black, #000);
    text-align: center;
    leading-trim: both;
    text-edge: cap;

    /* Body/Large/Regular - 18 */
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
}

.contact-hero-section .contact-annoucement {
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 2% 3%;
}

.contact-hero-section .contact-annoucement > .col-lg-3{
    display: flex;
    width: 411px;
    height: 246px;
    padding: 40px 28px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: var(--Grays-G2, #F5F6FA);
    transition: .2s ease-in-out;
    
}

.contact-hero-section .contact-annoucement > .col-lg-3:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.contact-hero-section .start-project {
    display: flex;
    width: 240px;
    height: 56px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Primary-P6---BASE, #C0FF00);
    background: var(--Primary-P6---BASE, #C0FF00);

    color: var(--Text-T---Black, #000);
    text-align: center;
    leading-trim: both;

    text-edge: cap;
    /* Button/B - 16 */
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 75% */
}

.contact-us-form {
    padding: 60px 79px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0%;
}

.contact-us-form .contact-google-map{
    height: 610px;
    overflow: hidden;
}

.contact-us-form form .row .col-6 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-us-form form .send-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-us-form .form-button {
    background: #C0FF00;
    width: 193px;
    height: 56px;
    padding: 0 16px;
    border: none;
    outline: none;
    border-radius: 16px;

    color: black;
    font-size: 16px;
    font-family: 'Manrope';
    font-weight: 700;
    line-height: 12px;
    word-wrap: break-word;
    letter-spacing: 0.1rem;
}

.contact-us-form .form-button:hover {
    background: #9ac90a;
}


.contact-us-form .form-check-input {
    border: 2px solid #7fa900;
}

.contact-us-form .form-check-input:checked {
    background-color: #C0FF00;
    border-color: #C0FF00;
}

.contact-us-form .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url('https://res.cloudinary.com/dqg52thyo/image/upload/v1702633019/check_uav0t9.svg');
}

.contact-us-form .form-check-input {
    background-size: 12px;
}

.loader {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* ------------------------------------------------------------------------------------------------------------------ */
/*                                        Media Queries                                                               */
/*--------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1433px) {
    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 347px;
    }
}

@media screen and (max-width: 1250px) {
    .contact-google-map {
        display: flex;
    }

    .contact-google-map img {
        width: 100%;
        height: 850px;
    }

    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 272px;
    }
}

@media screen and (max-width: 1024px) {
    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 264px;
    }

    .contact-hero-section .contact-annoucement {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        padding: 0%;
    }

    .annoucement-alert h2{
        font-size: 22px;
        text-align: center;
    }

    .contact-hero-section .contact-annoucement > .col-lg-3{
        display: flex;
        width: 261px;
        height: 200px;
        padding: 40px 28px;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        text-align: center;
        border-radius: 16px;
        background: var(--Grays-G2, #F5F6FA);
    }

    .contact-us-form {
        padding: 12px !important;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .contact-google-map, .contact-us-image {
        margin-top: 4rem;
    }

    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 296px;
    }
}

@media screen and (max-width: 768px) {
    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 238px;
    }

    .contact-hero-section {
        padding: 60px 50px;
    }

    .contact-us-form {
        padding: 60px 0 60px 30px;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .contact-us-image {
        width: 300px;
        padding: 0;
        margin-left: 15px;
    }

    .contact-us-image img {
        width: 100%;
    }

    .contact-us-phone {
        width: 120px;
        height: 120px;
        right: 10px;
    }

    .contact-us-vector {
        top: -15px;
        left: -15px;
        width: 30px;
    }
    
    .contact-us-elements {
        top: 60px;
        left: -40px;
        width: 56px;
    }

    .contact-hero-section .contact-annoucement > .col-lg-3{
        height: 150px;
        padding: 20px 28px;
        width: 100%;
        flex-direction: row;
        gap: 40px;
    }

    .contact-hero-section {
        padding: 60px 30px;
    }

    .annoucement-alert {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }


    
}

@media screen and (max-width: 430px) {
    .contact-hero-section {
        padding: 60px 20px;
    }

    .contact-us-form {
        padding: 60px 0 60px 20px;
        width: 100%;
    }

    .contact-us-form .contact-google-map{
        height: 350px;
        overflow: hidden;
    }

    .contact-hero-section .contact-annoucement > .col-lg-3{
        width: 100%;
        height: 150px;
        flex-direction: row;
        justify-content: center !important;
        gap: 16px;     
        padding: 20px;
        text-align: center !important;
    }
    .contact-hero-section .contact-annoucement > .col-lg-3 > img{
      height: 30px !important;
      width: 30px !important;
    }

    .annoucement-alert {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0px;
    }
}