.project-details {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 42px;
    padding: 60px 79px;
}

.project-details .col-lg-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}


.project-details .project-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.project-details .project-field h1 {
    align-self: stretch;
    color: var(--Text-T---Black, #000);
    leading-trim: both;
    text-edge: cap;

    /* Heading/H3 - 40 */
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
}

.project-details .project-field p {
    align-self: stretch;
    color: var(--Text-T---Black, #000);
    leading-trim: both;
    text-edge: cap;

    /* Heading/H3 - 40 */
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 100% */
}

.project-details .field-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.project-details .field-option div.mb-3 {
    align-self: stretch;
}

.project-details .field-option div.mb-3 .form-control {
    width: 100%;
}

.project-details .field-option div.mb-3.row {
    row-gap: 8px;
}

.project-details .field-option div.mb-3.row .skill {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 200px;
    background: var(--Grays-G2, #F5F6FA);
}

.project-details .field-option div.mb-3.row .skill > p{
    margin-bottom: 0;
}

.project-details .col-lg-6 .continue {
    display: flex;
    width: 193px;
    height: 56px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Primary-P6---BASE, #C0FF00);
    background: var(--Primary-P6---BASE, #C0FF00);

    color: var(--Text-T---Black, #000);
    text-align: center;
    leading-trim: both;
    text-edge: cap;

    /* Button/B - 16 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 75% */
}

.project-details .col-lg-8 {
    display: flex;
    height: 521px;
    padding: 0px 0px 10.25px 7px;
    justify-content: flex-end;
    align-items: center;
}

.project-details .col-lg-5 img {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .project-details {
        row-gap: 60px;
        padding: 60px 30px;
    }
    .project-details .col-lg-6 {
        order: 1;
    }
}

@media screen and (max-width: 425px) {
    .project-details .col-lg-6 {
        align-items: center;
    }
}

@media screen and (max-width: 375px) {
    .project-details {
        padding: 60px 10px;
    }
}