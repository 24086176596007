.project-journey {
    width: 100vw !important;
    height: 100%;
    /* background: #000; */
    /* background-color: lightgreen; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 42px;
    /* padding: 60px 79px; */
    margin: 0%;
    padding: 15px !important;
}

.project-journey .col-lg-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}


.project-journey .project-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.project-journey .project-field h1 {
    align-self: stretch;
    color: var(--Text-T---Black, #000);


    /* Heading/H3 - 40 */
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
}

.project-journey .project-field p {
    align-self: stretch;
    color: var(--Text-T---Black, #000);
    leading-trim: both;
    text-edge: cap;

    /* Heading/H3 - 40 */
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 100% */
}

.project-journey .field-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.project-journey .field-option .form-check {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    padding: 24px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.8px solid var(--Grays-G10, #EBEDF0);
    background: var(--Grays-Light-to-Dark, #FFF);

    cursor: pointer;
}

.project-journey .field-option > .form-check.active {
    border-color: var(--Grays-G10, #15181F);
}

.project-journey .field-option > .form-check > .form-check-input {
    font-size: 24px;
    margin-left: 0;
    accent-color: green;

}

.project-journey .field-option > .form-check > .form-check-input > input[type=radio]{
    accent-color: black !important;
}


.project-journey .field-option > .form-check > .form-check-label > h3 {
    display: flex;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--Text-T---Black, #000);
    leading-trim: both;
    text-edge: cap;

    /* Body/Large/Semibold - 18 */
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
}

.project-journey .field-option > .form-check > .form-check-label > p {
    align-self: stretch;
    color: var(--Grays-G7, var(--Grays-G7, #767C8C));
    leading-trim: both;
    text-edge: cap;
    
    /* Body/Normal/Regular - 16 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.project-journey .col-lg-6 .project-journey-button {
    display: flex;
    width: 193px;
    height: 56px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Primary-P6---BASE, #C0FF00);
    background: var(--Primary-P6---BASE, #C0FF00);

    cursor: pointer;
}

.project-journey .col-lg-6 .project-journey-button > .continue {
    color: var(--Text-T---Black, #000);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    border: none;
    outline: none;
    background-color: var(--Primary-P6---BASE);

    /* Button/B - 16 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 75% */
}

.project-journey .col-lg-8 {
    display: flex;
    height: 521px;
    padding: 0px 0px 10.25px 7px;
    justify-content: flex-end;
    align-items: center;
}

.project-journey .col-lg-5 img {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .project-journey {
        row-gap: 60px;
        padding: 60px 30px;
    }

    .project-journey .col-lg-6 {
        order: 1;
    }
}

@media screen and (max-width: 425px) {
    .project-journey .col-lg-6 {
        align-items: center;
    }

    .project-journey .project-field h1 {
        align-self: stretch;
        color: var(--Text-T---Black, #000);
        /* Heading/H3 - 40 */
        font-family: Manrope;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 100% */
    }
    
}

@media screen and (max-width: 375px) {
    .project-journey {
        padding: 60px 10px;
    }
}