.my-our-services {
	background: #f5f6fa;
	padding-top: 2rem;
}
.our-services h1 {
	font-size: 40px;
	font-family: 'Manrope';
	font-weight: 700;
	line-height: 40px;
	word-wrap: break-word;
}
.my-our-services .services-cards .card:hover {
	border-color: #b6e628;
	/* background-color: #b6e628; */
}
.my-our-services .services-cards .card:hover .services-card-title > h6 {
	color: white;
	/* background-color: #b6e628; */
}

.my-our-services
	.services-cards
	.card:hover
	my-our-services
	.services-cards
	.card
	> .service-lists
	h6 {
	color: white !important;
}

.services-cards .card {
	display: flex;
	height: auto;
	cursor: pointer;
	width: 100%;
}
.servies-main-title h1 {
	color: black;
	text-align: center;
	font-size: 40px;
	font-family: 'Manrope';
	font-weight: 700;
	line-height: 40px;
	word-wrap: break-word;
}

.card-image-logo {
	width: 60px;
	height: 60px;
  padding: 4px;
  
	background: #15181f;
	border-radius: 8px;
	display: flex;
	align-items: center;
  justify-content: center;
}

.icon_svg{
  fill: green !important;
   color: #c0ff00; /* Change the color dynamically */
}

.svg{
  color: green !important;
}

.my-our-services .services-cards .card > .service-lists h6 {
	color: black;
	font-size: 24px;
	font-family: 'Manrope';
	font-weight: 700;
	line-height: 24px;
	word-wrap: break-word;
}

.services-card-title h6 {
	color: black;
	font-size: 20px;
	font-family: 'Manrope';
	font-weight: 700;
	line-height: 24px;
	word-wrap: break-word;
}
.services-cards .card {
	border: none !important;
	border-radius: 16px;
}
.services-card-title .card-description {
	color: #767c8c;
	font-size: 16px;
	font-family: 'Manrope';
	font-weight: 400;
	line-height: 24px;
	word-wrap: break-word;
}
.services-card-list .services-list {
	/* font-family: 'Manrope';    */
	font-size: 16px;
}
.services-card-list img {
	/* width: 20px; */
	height: auto;
}
.services-nav-btn button {
	padding: 24px;
	background: #c0ff00;
	border-radius: 16px;
	border: 1px solid #c0ff00;
	transition: background 0.25s ease-in-out;
}
.services-nav-btn button:hover {
	background: #9ac90a;
}

@media screen and (max-width: 450px) {
	.servies-main-title h1 {
		color: black;
		text-align: center;
		font-size: 30px;
		font-family: 'Manrope';
		font-weight: 700;
		line-height: 40px;
		word-wrap: break-word;
	}
}

@media screen and (max-width: 1024px) {
	.servies-main-title h1 {
		color: black;
		text-align: center;
		font-size: 30px;
		font-family: 'Manrope';
		font-weight: 700;
		line-height: 40px;
		word-wrap: break-word;
	}

	.my-our-services .services-cards .card > .service-lists h6 {
		font-size: 15px !important;
	}
}
