.card-body p {
  color: black;
  font-size: 19px;
  font-family: Manrope;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.card-footer-image {
  width: 60px;
  height: 60px;
  padding: 10px;
  background: #15181f;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.carousel-inner .carousel-item img,
.carousel {
  height: fit-content;
}

.testimonial-small {
  display: none;
}

@media screen and (min-width: 768px) {
  .cards-wrapper {
    display: flex;
  }
  .card {
    border-radius: 16px;
    border: 3.6px #f5f6fa solid;
    margin: 0 0.5em;
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 767px) {
  .testimonial-large {
    display: none;
  }
  .testimonial-small {
    display: block;
  }
}

.transition-cards {
  transition: 0.3s all ease-in-out;
  background-color: white;
}

.multi-line-text-truncate {
  overflow: hidden;
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 20px auto;
}


.reviewCards{
  /* background-color: green; */
  padding: 10px;
  /* margin: 10px; */
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* width: 300px; */
  background-color: lightblue;
}


.slider-container{
  padding: 0 100px;
}

@media screen and (max-width: 1024px) {
  .slider-container{
    padding: 0 30px;
  } 

  .reviewCards{
    padding: 5px 0;
  }
}


@media screen and (max-width:450px) {
  .slider-container{
    padding: 0%;
  }

  .reviewCards{
    padding: 10px;
  }
}

.slider_Card{
  /* background-color: green; */
  padding: 10px;
  /* margin: 10px; */
  width: 100%;
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* width: 300px; */
  background-color: transparent;
}