.about-hero-section {
    width: 100%;
    height: 100%;
    background: white;
    padding: 4rem 0;
    overflow: hidden !important;
    display: flex;
    margin: 0%;
    justify-content: space-around;
    /* background-color: rgb(185, 243, 185) !important; */
}

.about-us-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 4rem;
    gap: 20px;
}

.about-us-content .about-hero-section-title {
    color: black;
    font-size: 48px;
    font-family: 'Manrope';
    font-weight: 700;
    line-height: 48px;
    word-wrap: break-word
}

.about-us-content .about-hero-section-description {
    color: #333743;
    font-size: 18px;
    font-family: 'Manrope';
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word
}

.about-us-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 496px;
    height: 338px;
}

.about-us-image .about-us-rectangle{
    width: 410px;
    height: 338px;
    border-radius: 8px;
    z-index: 1;
}

.about-us-content .form-button {
    background: #C0FF00;
    width: 239px;
    height: 56px;
    padding: 0 24px;
    border: none;
    outline: none;
    border-radius: 16px;
}

.about-us-content .form-button:hover {
    background: #9ac90a;
}

.about-us-vector {
    position: absolute;
    top: -20px;
    left: 23px;
    width: 43px;
    height: 43px;
    z-index: 2;
}

.about-us-bracket {
    position: absolute;
    padding: 16px 28px;
    background: black;
    border-radius: 24px;
    width: 154px;
    height: 154px;
    border: 8px solid white;
    right: -7px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.about-us-elements {
    position: absolute;
    top: 117px;
    left: -4px;
    width: 66px;
    height: 103px;
    z-index: 2;
}

.about-us-background {
    position: absolute;
}

.about-us-approach {
    width: 100%;
    height: 100%;
    padding: 60px 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-approach .approach-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.about-us-approach .approach-area .approach-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

.about-us-approach .content-title {
    color: 'black';
    font-size: 40px;
    font-family: 'Manrope';
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word   
}

.about-us-approach .content-desc {
    color: 'black';
    font-size: 18px;
    font-family: 'Manrope';
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word
}

.about-us-approach .approach-services {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    row-gap: 16px;
}

.about-us-approach .approach-services .service-container {
    width: 303px;
    height: 100%;
    padding: 40px 28px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 16px;
    gap: 24px;
}

.about-us-approach .approach-services .service-container .service-title {
    color: #C0FF00;
    font-size: 40px;
    font-family: 'Manrope';
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word
}

.about-us-approach .approach-services .service-container .service-desc {
    color: #C0FF00;
    font-size: 18px;
    font-family: 'Manrope';
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word
}

/* ------------------------------------------------------------------------------------------------------------------ */
/*                                        Media Queries                                                               */
/*--------------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 1250px) {
    .about-google-map {
        display: flex;
    }

    .about-google-map img {
        width: 100%;
    }
}


@media screen and (max-width: 1024px) {
    .about-hero-section-title{
        font-size: 36px !important;
    }
}

@media screen and (max-width: 991px) {
    .about-google-map, .about-us-image {
        margin-top: 4rem;
    }

    .about-hero-section .about-us-image {
        order: 1;
    }
}

@media screen and (max-width: 600px) {
    .about-us-form {
        padding: 60px 30px;
    }

    .about-us-image {
        width: 300px;
        padding: 0;
        margin-left: 15px;
    }

    .about-us-image img {
        width: 100%;
    }

    .about-us-bracket {
        width: 120px;
        height: 120px;
        right: 10px;
    }

    .about-us-vector {
        top: -15px;
        left: -15px;
        width: 30px;
    }
    
    .about-us-elements {
        top: 60px;
        left: -40px;
        width: 56px;
    }

    .about-hero-section {
        width: 100%;
        height: 100%;
        background: white;
        padding: 4rem 0;
        overflow: hidden !important;
        display: flex;
        margin: 0%;
        justify-content: center;
        /* background-color: rgb(185, 243, 185) !important; */
    }

    .about-us-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 1rem;
        gap: 20px;
    }

    .about-us-approach {
        width: 100%;
        height: 100%;
        padding: 10px !important;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}