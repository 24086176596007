.services-cards .card{
    transition: .3s all;
}

.services-cards .card:hover{
    transform: scale(1.1);
    background: #1b1e26;
    color: #fff;
}

.services-cards .card:hover .service-lists > h6 {
    color: #fff;
}

.checkIcon{
    height: 10px;
    margin-right: 20px;
}