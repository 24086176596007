@media screen and (min-width: 1400px ){
    .services-card-list {
        width: 100%;
        height: 105px;
        margin-top: 20px;
    }
    
    .services-card-title {
        width: 100%;
        height: 100%;
    }

    .service-lists {
        width: 100%;
        height: 75px;
    }


}

@media screen and (max-width: 1400px) {
    .section-card-two {
        grid-template-columns: repeat(2, 1fr) !important;
        column-gap: 40px !important;
    }

    .services-card-title {
        width: 100%;
        height: 100%;
    }

    .service-lists {
        width: 100%;
        min-height: 75px;
    }

    .services-card-list {
        width: 100%;
        height: 150px;
    }

    .services-card-title .card-description {
        height: 100px;
    }
}

@media screen and (max-width: 1300px) {
    .customers {
        padding-inline: 70px !important;
    }
      
    .customers__list {
        padding: 50px !important;
        gap: 60px !important;
    }

    .customers__list > img {
        width: 90% !important;
        height: 90% !important;
    }

    .projects {
        padding: 60px 70px !important;
    }

    .section-card .card {
        width: 500px !important;
    }

    .section-card .card .card-image > img {
        width: 500px !important;
    }
}


@media screen and (max-width: 1240px){
    .navbar {
        padding-inline: 17px !important;
    }

    .content__titles {
        font-size: 64px !important;
    }

    .content__description {
        width: 600px !important;
        font-size: 16px !important; 
    }

    .content__button {
        width: auto;
        gap: 6px;
    }

    .content__button--title {
        font-size: 15px !important;
    }

    .content__elements {
        width: 63px !important;
        height: 100px !important;
    }

    .content__tag {
        right: 80px !important;
    }

    .content__layer {
        left: 100px !important;
    }

    .services {
        padding: 60px 30px !important;
    }

    .section-card .card {
        width: 480px !important;
    }

    .section-card .card .card-image > img {
        width: 480px !important;
    }
}

@media screen and (max-width: 1199px) {
    .services-card-list {
        width: 100%;
        height: 200px;
    }

    .services-card-title {
        width: 100%;
        height: 200px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 1024px){
    .navbar__title {
        padding: 0.5rem 0;
    }

    /* .navbar__list--area {
        display: none !important;
    } */

    .content-section .section-text .text-title{
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
    }


    
    .services-card-title {
        width: 100%;
        height: 80px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .services-card-list {
        width: 100%;
        height: 160px;
    }

    .navbar__menu {
        position: relative;
        display: flex !important;
        height: 20px;
        width: 28px;
        cursor: pointer;
        transition: all 0.25 ease-in-out;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
    }
      
    .navbar__menu--btn {
        z-index: 999;
        width: 28px;
        height: 3px;
        background-color: white;
        transition: all 0.5s ease-in-out;
    }
      
    .navbar__menu--btn::before {
        content: '';
        position: absolute;
        top: -0.5px;
        width: 28px;
        height: 3px;
        background-color: white;
        transition: all 0.5s ease-in-out;
    }
      
    .navbar__menu--btn::after {
        content: '';
        position: absolute;
        top: 1.1rem;
        width: 20px;
        height: 3px;
        background-color: white;
        transition: all 0.5s ease-in-out;
    }

    /* .content.service {
        background: url('https://res.cloudinary.com/dqg52thyo/image/upload/v1701089526/Crazy%20Tech/hero-mobile_lkds78.png') !important;
    } */

    .content__titles {
        font-size: 56px !important;
    }

    .content__description {
        width: 550px !important;
    }

    .content__button {
        width: auto !important;
        gap: 6px;
    }

    .content__button--title {
        font-size: 14px !important;
    }

    .content__tag {
        right: 40px !important;
    }

    .content__frame {
        right: 200px !important;
    }

    .content__bracket {
        left: 90px !important;
    }

    .content__layer {
        left: 60px !important;
    }

    .customers {
        display: none !important;
    }

    .services__list--area {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .services {
        padding: 60px 80px !important;
    }

    .section-card .card {
        width: 460px !important;
        height: 350px !important;   
        text-align: center !important; 
    }

    .section-card .card .card-content .content-section {
        justify-content: center !important;
        align-items: center !important;

    }

    .section-card .card .card-image > img {
        width: 100% !important;
    }
}

@media screen and (max-width: 991px) {
    .services-card-list {
        width: 100%;
        height: 270px;
    }

    .service-lists {
        width: 100%;
        height: 100px;
    }

    .services-card-title .card-description {
        height: 150px;
    }

    .services-card-title {
        height: 280px;
    }
}

@media screen and (max-width: 768px) {
    .services-card-list {
        width: 100%;
        height: 230px;
    }

    .services-card-title {
        height: initial;
    }
}

@media screen and (max-width: 850px){
    .section-card-two {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .content__tag {
        top: auto !important;
        bottom: 70px !important;
    }

    .content__frame {
        top: auto !important;
        bottom: 70px !important;
    }

    .content__bracket {
        top: auto !important;
        bottom: 70px !important;
        left: 70px !important;
    }

    .content__layer {
        top: 70px !important;
        left: 171px !important;
    }

    .services {
        padding: 60px 60px !important;
    }

    .section-card {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .section-card .card {
        width: 608px !important;
        height: 100% !important;
    }

    .section-card .card .card-image > img {
        width: 100% !important;
    }
}

@media screen and (max-width: 767px){
    .carousel-buttons {
        padding-top: 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 72px;
    }

    .carousel-control-next, .carousel-control-prev {
        position: relative !important;
    }
}

@media screen and (max-width: 700px){
    .services__list--area {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .services {
        padding: 60px 100px !important;
    }

    .section-card .card {
        width: 570px !important;
    }

    .section-card .card .card-image > img {
        width: 570px !important;
    }

    .content-title {
        font-size: 36px !important;
    }
}

@media screen and (max-width: 600px){
    .content__titles {
        font-size: 48px !important;
        width: 450px !important;
    }
    .content__titles {
       
        line-height: 60px !important ; 
    }

    .content__description {
        width: 450px !important;
    }

    .content__elements {
        left: 40px !important;
    }

    .content__tag {
        right: 20px !important;
    }

    .content__frame {
        top: 470px !important;
        right: auto !important;
        left: 40px !important;
    }

    .content__bracket {
        left: 30px !important;
    }

    .content__layer {
        left: auto !important;
        right: 43px !important
    }

    .content__ellipse {
        top: 480px !important;
        right: 43.16px !important;
    }

    .services {
        padding: 60px 90px !important;
    }

    .section-card .card {
        width: 450px !important;
    }

    .section-card .card .card-image > img {
        width: 450px !important;
    }
}

@media screen and (max-width: 575px) {
    .services-card-list {
        width: 100%;
        height: fit-content;
    }

    .services-card-title .card-description {
        height: initial;
    }
}

@media screen and (max-width: 500px){
    .content__titles {
        width: 86% !important;
        white-space: wrap;
        word-wrap: break-word;
    }

    .content__description {
        width: 80% !important;
        white-space: wrap;
        word-wrap: break-word;
    }

    .content__tag {
        width: 85px;
        height: 85px;
        padding: 15px !important;
    }
    .content__titles {
      
        line-height: 56px;
    }

    .content__tag--img > img {
        width: 44px !important;
        height: 46px !important;
    }

    .content__frame {
        top: 50% !important;
    }

    .content__bracket {
        width: 110px;
        height: 85px;
        padding-inline: 10px !important;
    }

    .content__bracket--img > img {
        width: 80.2px;
        height: 49.8px;
        margin-top: -5px;
    }

    .content__ellipse {
        top: 50% !important;
    }

    /* .customers {
        padding-inline: 35px !important;
    }
      
    .customers__list {
        padding: 20px !important;
        gap: 30px !important;
    }

    .customers__list > img {
        width: 18% !important;
        height: 35% !important;
    }

    .customers__list > img:nth-child(4) {
        height: 20px !important;
    } */

    .services {
        padding: 60px 50px !important;
    }

    .section-card .card {
        width: 380px !important;
    }

    .section-card .card .card-image > img {
        width: 380px !important;
    }

    .section-card .card .card-image > img {
        width: 300px !important;
        height: 200px;
    }

    .content-section .section-text .text-title{
        font-size: 18px;
        text-align: center !important;
        margin: 0 auto;
    }

    .section-card .card {
        width: 340px !important;
        border: none;
        text-align: center;
        border: none !important;
        background-color: transparent !important;
    }
    .section-card .card .card-image > img {
        width: 340px !important;
        height: 250px;
    }

    .content-section .section-text{
        display: flex;
        width: 100%;
        justify-content: center;
        /*background: #3B5998;*/
        /* margin: 30px 0; */
    }   

    .content-title {
        font-size: 28px !important;
    }


    .content__list {
        margin: 50px 0 260px 0 !important;
        gap: 30px !important;
    }

}

@media screen and (max-width: 425px){
    .carousel-inner .carousel-item img,
    .carousel {
        height: 911px !important;
    }

    .testimonial-carousel .carousel-inner .carousel-item img, .testimonial-carousel {
        height: 100% !important;
      }

    .content-section .section-text{
        display: flex;
        width: 100%;
        justify-content: center;
        /*background: #3B5998;*/
        /* margin: 30px 0; */
    }

    .content-section .section-text .text-title{
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
    }

    .section-card .card {
        width: 340px !important;
        border: none;
        text-align: center;
        border: none !important;
        background-color: transparent !important;
    }
    .section-card .card .card-image > img {
        width: 340px !important;
        height: 250px;
    }

}

@media screen and (max-width: 400px){
    .services {
        padding: 60px 5px !important;
    }


    .section-card .card {
        width: 340px !important;
        border: none;
        text-align: center;
    }

    .section-card .card .card-image > img {
        width: 340px !important;
        height: 250px;
    }

    
    .section-card-two .div, 
    .section-card-two .div .div-5,
    .section-card-two .div .div-6 {
        width: 100%;
        /* background: #000; */
    }

    .carousel-inner .carousel-item img,
    .carousel {
        height: 611px !important;
    }

    .content-section .section-text{
        display: flex;
        width: 100%;
        justify-content: center;
        /*background: #3B5998;*/
        /* margin: 30px 0; */
    }

    .content-section .section-text .text-title{
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
    }

    .heroSection{
    /*background: #3B5998;*/
        margin-top: 0 !important;
        height: max-content;
    }

   


}