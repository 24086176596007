.footer-section {
                  background: #151414;
                  flex: none;
                }
                .footer-logo a{
                  color: white;
                   font-size: 27.81px;
                   font-family: Raleway;
                   font-weight: 500;
                   word-wrap: break-word;
                   text-decoration: none;
                }
                
              
                .footer-logo {
                  margin-bottom: 30px;
                }
                .footer-logo img {
                    max-width: 200px;
                }
                .footer-text p {
                  margin-bottom: 14px;
                  color: #EBEDF0;
                  font-size: 16px;
                  font-family: Manrope;
                  font-weight: 400;
                  line-height: 24px;
                  word-wrap: break-word
                }
                .footer-social-icon span {
                  color: #fff;
                  display: block;
                  font-size: 20px;
                  font-weight: 700;
                  font-family: 'Poppins', sans-serif;
                  margin-bottom: 20px;
                }
                .footer-social-icon a {
                  color: #fff;
                  font-size: 16px;
                  margin-right: 15px;
                }
                .footer-social-icon i {
                  height: 40px;
                  width: 40px;
                  text-align: center;
                  line-height: 38px;
                  border-radius: 50%;
                }
                .facebook-bg{
                  background: #3B5998;
                }
                .twitter-bg{
                  background: #55ACEE;
                }
                .google-bg{
                  background: #DD4B39;
                }
                .instagram-bg {
                  background: linear-gradient(325deg, #FBE18A 0%, #FCBB45 21%, #F75274 38%, #D53692 52%, #8F39CE 74%, #5B4FE9 100%);                
              }
              .linkedin-in-bg{
                  background: #006699; 
              }
              .pinterest-bg{
                  background: #FF0000
              }

                .footer-links p{
                  color: #C8CCD9;
                  font-size: 14px;
                  font-family: Manrope;
                  font-weight: 400;
                  line-height: 22px;
                  word-wrap: break-word;
                  position: relative;
                  cursor: pointer;
                }

                .footer-links ul li:first-of-type:hover {
                  color: #C0FF00;
                }
               
                .footer-links ul li {
                  display: inline-block;
                  /* float: left; */
                  width: 100%;
                  margin-bottom: 15px;
                  margin-top: 5px;
                  cursor: pointer;
                }
                .footer-links ul li a:hover{
                  color: #C0FF00;
                }
                .footer-links ul li a {
                  color: #878787;
                  text-transform: capitalize;
                  text-decoration: none;
                  color: white;
                  font-size: 16px;
                  font-family: Manrope;
                  font-weight: 500;
                  /* line-height: 27px; */
                  /* word-wrap: break-word */
                }
                
                 /* .footer-titles span {
                  border-bottom: 1px solid #C0FF00;
                  
                 } */
                 .footer-titles {
                  position: relative;
                }
                
                .footer-main-titles {
                  position: relative;
                  display: inline-block;
                }
                
                .footer-main-titles::after {
                  content: "";
                  position: absolute;
                  bottom: -7px; 
                  left: 0;
                  width: 40%;
                  height: 2px;
                  background-color: #d4fd57; 
                  display: block;
                }