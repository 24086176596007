.mobile_nav_menus{
    margin: 5px;
}
.toggle_button{
    display: none;
    border: none;
    outline: none;
    /* background-color: aliceblue; */
}

.mobile_menu{
    display: none;
}



.navbar-toggler-icon{
    color: white !important;
}


.menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }
  
  .menu-icon div {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .change .bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change .bar2 {
    opacity: 0;
  }
  
  .change .bar3 {
    transform: rotate(45deg) translate(-6px, -3px);
  }


@media screen and (max-width: 815px){
    .toggle_button{
        display: block;
    }

    .mobile_menu{
        display: block;
    }

    .navbar__list--area {
        display: none !important;
    } 
}


@media screen and (max-width:1024px) {
  
}